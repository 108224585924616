import React, { useState } from "react";
import { Link } from "react-router-dom";

import "./Team.scss";

const Team = () => {
  const [readMore1, setReadMore1] = useState(true);
  const [readMore2, setReadMore2] = useState(true);
  const [readMore3, setReadMore3] = useState(true);
  return (
    <div className="w-100 d-flex align-items-center flex-column">
      <div className="tm-hero--wrapper">
        <div className="tm-hero">
          <div className="tm-hero-text fadeInAndUp">
            <h1>Our Team</h1>
            <h3>Clean Energy Strategies team biographies</h3>
          </div>
        </div>
      </div>
      <div className="tm-sec2">
        <div className="tm-sec2--inner">
          <div className="tm-bio-wrapper">
            <h2>Kristina Photios</h2>
            <p className="blue">Director</p>
            <p className={readMore1 ? "tm-bio" : "full tm-bio"}>
              Kristina has over 15 years experience in corporate strategy,
              mergers & acquisitions and corporate finance. <br /> <br />{" "}
              Starting with KPMG in assurance & advisory, she then worked at
              Macquarie Bank in mergers & acquisitions. <br /> <br />
              Subsequently as Strategy Manager within Nine Entertainment Group
              and then in Business Development & Strategy with Optus Digital.
              <br /> <br />
              Kristina led the development of Optus Digital's ehealth and
              elearning strategies, working across Sydney, San Francisco and
              Singapore. <br /> <br />
              She also led a number of internal M&A transactions and assisted in
              the structure & roll-out of the Optus Innov8 Seed Fund. <br />{" "}
              <br />
              Kristina was a member of the NSW Government ehealth PPP Forum,
              which reported to the NSW Treasurer and NSW Minister for Ageing
              and Disability Services.
              <br /> <br /> Kristina is a passionate leader in the conservative
              space for climate policy and renewables.
            </p>
            <button
              className="rm-btn-2"
              onClick={() => setReadMore1(!readMore1)}
            >
              {readMore1 ? "Read more" : "Show less"}
            </button>
          </div>
          <div className="bio-img-wrapper bio-img1"></div>
        </div>
      </div>
      <div className="tm-sec2">
        <div className="tm-sec2--inner">
          <div className="tm-bio-wrapper">
            <h2>Ian Hancock</h2>
            <p className="blue">Director</p>
            <p className={readMore2 ? "tm-bio" : "full tm-bio"}>
              Ian specialises in public policy and corporate affairs. He has
              experience in strategy, project management and delivery and
              stakeholder engagement for clients in a range of industries,
              including professional services, energy and resources,
              infrastructure and planning. <br /> <br />
              Before commencing at Clean Energy Strategies, he worked in the
              Sydney office of global law firm Baker & McKenzie. In the
              Corporate and Energy & Resources teams, Ian worked on a broad
              range of corporate governance, joint ventures, general commercial
              and restructuring matters, as well as mergers & acquisitions,
              including the privatisation of NSW’s electricity assets and sale
              of other major infrastructure. <br /> <br />
              He also has extensive experience in small business and the
              publishing industry, having owned and operated a commercial
              property maintenance business and worked as the Deputy Editor of a
              popular special-interest magazine and events promoter. <br />{" "}
              <br />
              Ian studied a Bachelor of Laws with First Class Honours and a
              Bachelor of Business (Economics) at the University of Technology,
              Sydney.
            </p>
            <button
              className="rm-btn-2"
              onClick={() => setReadMore2(!readMore2)}
            >
              {readMore2 ? "Read more" : "Show less"}
            </button>
          </div>
          <div className="bio-img-wrapper bio-img2 "></div>
        </div>
      </div>
      <div className="tm-sec2">
        <div className="tm-sec2--inner">
          <div className="tm-bio-wrapper">
            <h2>Michael Photios</h2>
            <p className="blue">Director</p>
            <p className={readMore3 ? "tm-bio" : "full tm-bio"}>
              Michael Photios brings a wealth of experience in politics and
              business. <br /> <br />
              First elected to the NSW Parliament in 1988, Michael Photios went
              on to serve in the NSW Parliament for over a decade. As Minister
              for Multicultural and Ethnic Affairs and Minister Assisting the
              Justice Minister, Michael was the then youngest ever Cabinet
              Minister in Australia. <br /> <br />
              Prior to this, Michael served as Chairman of the NSW Government
              Environment Committee, and the powerful Public Accounts Committee.
              <br /> <br />
              In opposition, Michael served in a senior Cabinet portfolio. As
              Shadow Minister for Transport, he played a key role in
              scrutinising rail reform across NSW, and keenly promoted the role
              of PPPs in service delivery. As one of the first advocates of an
              integrated transport management system in NSW, Michael was
              instrumental in ensuring its inclusion into the Coalition’s policy
              platform and current reform agenda. <br /> <br />
              As Shadow Minister for Tourism, Michael was arguably the most
              vocal partner for the NSW tourism industry. <br /> <br />
              After retiring from Parliament in 1999, Michael established
              himself as a leading executive in government relations across the
              country. In this capacity, he worked for more than a decade with a
              range of major private sector corporations and public sector
              agencies. <br /> <br />
              Along with his contemporary and extensive government networks,
              Michael brings his public policy, advocacy and commercial
              experience to Clean Energy Strategies.
            </p>
            <button
              className="rm-btn-2"
              onClick={() => setReadMore3(!readMore3)}
            >
              {readMore3 ? "Read more" : "Show less"}
            </button>
          </div>
          <div className="bio-img-wrapper bio-img3 img-right"></div>
        </div>
      </div>
    </div>
  );
};

export default Team;
