import React from "react";

import "./Contact.scss";

const Contact = () => {
  return (
    <div className="w-100 d-flex justify-content-center align-items-center flex-column px-5">
      <div className="con-hero-text">
        <h1>Contact</h1>
      </div>
      <p className="w-100 mw-1400">Get in touch</p>
      <div className="con-grid">
        <div className="con-item1">
          <form
            name="contact"
            method="post"
            action="/success=1"
            data-netlify="true"
          >
            <input type="hidden" name="form-name" value="contact" />
            <p>
              <label>
                <input
                  placeholder="Full Name"
                  type="text"
                  name="name"
                  className="com-input"
                />
              </label>
            </p>
            <p>
              <label>
                <input
                  placeholder="Email Address"
                  type="email"
                  name="email"
                  className="com-input"
                />
              </label>
            </p>
            <p>
              <label>
                <textarea
                  rows="4"
                  cols="50"
                  id="large-input"
                  placeholder="Message"
                  name="message"
                ></textarea>
              </label>
            </p>
            <p>
              <button type="submit" id="snd-msg--btn">
                Send message
              </button>
            </p>
          </form>
        </div>
        <div className="con-item2">
          <h3>
            <a href="tel:+61 2 9221 1855">
              <h3>+61 2 9221 1855</h3>
            </a>
            <a href="mailto:team@cleanenergystrategies.com.au">
              <h3>team@cleanenergystrategies.com.au</h3>
            </a>
            Level 61 <br /> MLC Centre <br />
            Martin Place <br />
            NSW 2000
            <br /> Australia
          </h3>
        </div>
      </div>
    </div>
  );
};

export default Contact;
