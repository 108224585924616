import React from "react";
import "./App.scss";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Navbar from "./components/navbar";
import Landing from "./components/landing";
import Footer from "./components/footer";
import Advisory from "./components/advisory";
import Sitemap from "./components/sitemap";
import Team from "./components/team";
import Venture from "./components/venture";
import Contact from "./components/contact";
import Artical from "./components/artical";
import ThankYou from "./components/thankYou";

import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="app">
        <Navbar />
        <Switch>
          <Route exact path="/">
            <Landing />
          </Route>
          <Route path="/advisory">
            <Advisory />
          </Route>
          <Route path="/team">
            <Team />
          </Route>
          <Route path="/venture">
            <Venture />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/success=1">
            <ThankYou />
          </Route>
          <Route path="/why-are-energy-prices-so-high">
            <Artical />
          </Route>
        </Switch>
        <Sitemap />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
