import React from "react";

import "./Article.scss";

const Article = () => {
  return (
    <div className="w-100 d-flex justify-content-center flex-column align-items-center ">
      <div className="article-wrapper  fadeInAndUp px-5">
        <p>Article</p>
        <h1>Why Are Electricity Prices Rising?</h1>
        <h2 className="grey">
          We are seeing an unprecedented rise in electricity prices across the
          board
        </h2>
        <div className="artical-avatar-wrapper  mt-5">
          <div className="article-avatar "></div>
          <div className="d-flex justify-content-between flex-column py-2">
            <h3 className="m-0">Kristina Photios</h3>
            <h3 className="m-0 blue">Director</h3>
            <h3 className="m-0 grey">Clean Energy Strategies</h3>
          </div>
        </div>
      </div>
      <div className="artical-img my-5"></div>
      <div className="article-wrapper px-5">
        <span className="drop-cap">W</span>
        <p>
          e are seeing an unprecedented rise in electricity prices across the
          board, with the impact being felt acutely across the business
          community.
        </p>
        <p>
          At the same time we are at the early stages of a fundamental
          transformation of the electricity sector and on the cusp of a very
          rapid transition towards new technologies.
        </p>
        <p>There are four underlying causes... </p>
        <h4 className="pt-3">1. WHOLESALE GAS PRICES</h4>
        <p>
          In the short-term, the biggest cause of high electricity prices is the
          cost of gas, which is increasingly setting the prices in the wholesale
          electricity market.
        </p>
        <p>Gas and electricity markets are closely connected</p>
        <p>
          In the wholesale electricity market, generators bid the quantity of
          electricity they are willing to supply and the price they want to
          receive in an auction process. Generators are dispatched in price from
          lowest to highest up to the level where supply requirements are met.
          The most expensive marginal generator sets the clearing price that is
          paid to all generators dispatched. The economics of gas-fired
          generators are being challenged by rising gas prices and tightening of
          supply, which then increases the spot price for the wholesale
          electricity market.
        </p>
        <p>
          In the past decade, the east coast gas market has fundamentally
          changed with three QLD LNG projects having significant impact on the
          domestic gas market. These LNG projects have entered into long-term
          LNG export agreements, with strict gas supply delivery requirements.
          However, some of these projects have experienced difficulties in
          extracting sufficient gas reserves.
        </p>
        <p>
          With rising international gas prices and simultaneous domestic
          restrictions on CSG exploration, significant volumes of gas originally
          intended for the domestic market are being exported.
        </p>
        <p>
          As a result, it is becoming increasingly difficult and more expensive
          for gas-fired generators to secure firm gas supply contracts.
        </p>
        <p>
          The rising cost of input costs for gas-fired generators is being
          translated in higher wholesale electricity spot prices, which then
          flow on to higher retail prices.
        </p>
        <p>
          The Federal Government has introduced measures, effective 1 July 2017,
          to impose export restrictions on gas to reduce domestic gas supply
          shortages, which will apply downward pressure on gas and electricity
          prices.
        </p>
        <h4 className="pt-3">2. TRANSMISSION AND DISTRIBUTIONS CHARGES</h4>
        <p>
          Large demand forecasting errors have contributed to a number of
          problems in the NEM.
        </p>
        <p>
          Previously, electricity demand increased with population and economic
          growth. From the late 2000’s, demand has moderated and then declined.
          Electricity forecasters did not realise immediately that this was a
          structural change and that consumer patterns have shifted permanently
          with the arrival of new technologies.
        </p>
        <p>
          Increased penetration of roof-top solar panels, changing industrial
          consumption and energy efficiency initiatives have contributed to a
          structural reduction of actual demand.
        </p>
        <p>
          As a result, there has been a significant degree of over-investment in
          recent transmission and distribution network infrastructure, with
          consumers bearing the cost of these investments through substantial
          transmission and distribution charges.
        </p>
        <p>
          The Federal Government has announced on 20th June 2017 that it will
          address these through strengthening the hand of the Australian Energy
          Regulator and limiting the ease of appeal, in line with the Finkel
          Review recommendations.
        </p>
        <h4 className="pt-3">3. PERFORMANCE AND TRANSPARENCY</h4>
        <p>
          Effective competition is a key driver of productive, efficient and
          innovative markets. There have been some suggestions made during the
          submission process to the Finkel Review, that the electricity retail
          market is not offering the same value to consumers as other markets,
          and in particular in comparison to other electricity markets overseas.
        </p>
        <p>
          A number of reviews and inquiries, including the Australian
          Competition and Consumer Commission, are currently considering issues
          related to the effectiveness of the electricity retail competition.
          Increased competitiveness in markets reduces the likelihood of above
          equilibrium pricing.
        </p>
        <h4 className="pt-3">4. INVESTOR UNCERTAINTY</h4>
        <p>
          The above reasons (1-3) are all short-term factors applying upward
          pressure on electricity pricing.
        </p>
        <p>
          But for the long-term, the more fundamental, underlying reason for
          rising prices in the wholesale market, especially in the price of
          forward contracts, is investor uncertainty. That uncertainty revolves
          around current and future emissions reduction policies.
        </p>
        <p>
          In the long-term, resolving the uncertainty will out downward pressure
          on prices by bringing new generation online. Increasing overall supply
          in the market will reduce prices at each point on the supply curve.
        </p>
        <p>
          The electricity sector acknowledges the role it will play in
          Australia’s national emission reduction efforts. The overwhelming view
          from stakeholders, especially from within the electricity sector, was
          the need for a credible and enduring emissions reduction mechanism.
        </p>
        <p>
          For a sector characterised by very high-cost and long-lived assets,
          policy transparency, credibility and durability are key.
        </p>
        <p>
          Without investment in new generation, the reliability of the NEM will
          be compromised.
        </p>
        <p>
          Long term emissions reduction trajectory for the electricity sector,
          notice of closure requirements for large generators and an emission
          reduction mechanism to provide policy stability and certainty will
          drive new investment. Investment in new generation increases supply
          and provides downward pressure on long-term electricity pricing.
        </p>
        <p>
          The information above has been sourced from the Independent Review
          into the Future Security of the National Electricity Market, June 2017
          (Finkel Review)
        </p>
        <div className="artical-avatar-wrapper  mt-5">
          <div className="article-avatar "></div>
          <div className="d-flex justify-content-between flex-column py-2">
            <h3 className="m-0">WRITTEN BY:</h3>
            <h3 className="m-0 blue">Kristina Photios</h3>
            <h3 className="m-0 grey">Clean Energy Strategies</h3>
          </div>
        </div>
      </div>
      <div className="divider"></div>
    </div>
  );
};

export default Article;
