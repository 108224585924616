import React from "react";
import { Link } from "react-router-dom";

import "./Advisory.scss";

const Advisory = () => {
  return (
    <div className="w-100 d-flex justify-content-center align-items-center flex-column">
      <div className="viso-hero mb-5">
        <div className="viso-item-1">
          <div className="px-5 fadeInAndUp">
            <h1>Energy Deals Advisory</h1>
          </div>
        </div>
        <div className="viso-item-2"></div>
      </div>
      <div className="viso-text">
        <p>
          <h4 className="mb-1">
            The team is proud to have advised and work with:{" "}
          </h4>
          <br />
          <br />
          <ul>
            <li>
              Australia's largest energy generator and distributor in their
              interface with government
            </li>
            <li>
              The world's leading supplier of reliable, environmentally-friendly
              and cost-efficient renewable energy solutions with strategic
              advice and government liaison
            </li>
            <li>
              Former US Vice President Al Gore and his team during his special
              purpose energy and climate Australia tour
            </li>
            <li>
              Australia Energy efficiency advisory for a leading industry
              association
            </li>
            <li>An Australian residential and commercial solar PV company</li>
            <li>
              Together with our sister company PremierState, we worked with the
              nation's most successful private pumped hydro consortium on its
              penultimate project which secured multi-million financial support
              from the Federal Government
            </li>
            <li>
              The Australian Hotels Association and Tourism Accommodation
              Australia, in a joint venture to secure the first industry scale
              power purchase agreement in Australia
            </li>
          </ul>
        </p>
        <p>
          For work in the government relations space we refer you to our sister
          companies <a href=" https://pstate.com.au/">PremierNational</a> and
          <a href=" https://pstate.com.au/"> PremierState</a>, which is proud to
          represent clients across a wide range of industries including major
          projects & planning | professional services | energy resources &
          mining | technology & communication | transport & infrastructure |
          tourism and hospitality construction | financial services| defence |
          transport | infrastructure | resources & mining | hospitality |
          communications and not-for-profit.
        </p>
      </div>
    </div>
  );
};

export default Advisory;
