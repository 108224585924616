import React from "react";

import "./Venture.scss";

import ImgClick from "../imgClick";

const Venture = () => {
  return (
    <div className="w-100 d-flex justify-content-center flex-column align-items-center">
      <div className="ven-heding fadeInAndUp px-5">
        <p>Featured</p>
        <h1>World First Industry-Scale PPA</h1>
        {/* <h2 className="grey">AHA / CES Joint Venture</h2> */}
      </div>
      <div className="ven-hre-img">
        <iframe
          title="AHA NSW Member Electricity Offer"
          src="https://player.vimeo.com/video/367021763"
          width="100%"
          height="100%"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
      </div>
      <div className="ven-text">
        <p>
          <span className="drop-cap">C</span>
          lean Energy Strategies (CES) has the unique expertise of successfully
          structuring, marketing and implementing the first industry-scale
          aggregate PPA. <br /> <br /> This is an emerging and new structure
          that has not previously been implemented on such a scale around the
          world. <br /> <br />
          The structure has significant potential as it enables consumer &
          industrial businesses to access the much cheaper renewable energy
          market.
          <h4 className="pt-3 mb-0">CES/ AHA Joint Venture</h4>
          <br /> <br /> CES has worked with the Australian Hotels Association
          (AHA) since 2017, to develop an innovative and cheaper long-term
          energy deal.
          <br /> <br /> This culminated in the establishment of a joint venture
          between CES and the industry’s national body - the AHA – in 2019
          <br /> <br /> Pubs are treated by energy retailers as individual
          businesses with small volumes, meaning their electricity prices are
          high They are required to renegotiate their electricity contracts
          regularly and therefore be exposed to the volatile electricity costs.
          <br /> <br />
          By aggregating their load, AHA Members were able to access the PPA
          market and negotiate a more competitive price for long-term
          electricity procurement while also supporting clean, renewable energy.
          <br /> <br />
          Lion joined the Offer as an anchor partner.
        </p>
        <div className="ven-img-wrapper">
          <ImgClick
            title="AHA Members Offer"
            image="/assets/AHA-pdfimage.jpg"
            imageL="/assets/AHA-pdfimage-L.jpg"
          />
          <ImgClick
            title="Fairfax News Snippet"
            image="/assets/news-snippet-S.jpg"
            imageL="/assets/news-snippet-L.jpg"
          />
        </div>
      </div>
    </div>
  );
};

export default Venture;
