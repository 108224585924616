import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import "./Navbar.scss";

const Navbar = () => {
  const [mobileMenu, setMobileMenu] = useState(false);
  return (
    <div className="navbar">
      <nav>
        <NavLink to="/">
          <img src="/ces-logo.svg" alt="Clean Energy Strategies" id="logo" />
        </NavLink>
        <button
          id="nav--button"
          onClick={() => setMobileMenu(!mobileMenu)}
          className={mobileMenu ? "position-fixed" : ""}
        >
          <div className="burger-line" />
          <div className="burger-line" />
          <div className="burger-line" />
        </button>
        <div
          className={
            mobileMenu ? "navbar-menu menufadeInAndUp" : "hidden navbar-menu "
          }
        >
          <ul>
            <li>
              <NavLink
                to="/advisory"
                onClick={() => setMobileMenu(false)}
                className="nav-link"
                activeClassName="is-active"
              >
                Advisory
                <div className="underline-wrapper">
                  <div className="underline-bar"></div>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/venture"
                onClick={() => setMobileMenu(false)}
                className="nav-link"
                activeClassName="is-active"
              >
                PPAs
                <div className="underline-wrapper">
                  <div className="underline-bar"></div>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/team"
                onClick={() => setMobileMenu(false)}
                className="nav-link"
                activeClassName="is-active"
              >
                Team
                <div className="underline-wrapper">
                  <div className="underline-bar"></div>
                </div>
              </NavLink>
            </li>
            <li className="mr-0">
              <NavLink
                to="/contact"
                onClick={() => setMobileMenu(false)}
                className="nav-link"
                activeClassName="is-active"
              >
                Contact
                <div className="underline-wrapper">
                  <div className="underline-bar"></div>
                </div>
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};
export default Navbar;
