import React, { useState } from "react";
import { Close20 } from "@carbon/icons-react";
import "./ImgClick.scss";

const ImgClick = ({ title, imageL, image }) => {
  const [imgState, setImgState] = useState(true);
  return (
    <button className="img-btn-wrapper" onClick={() => setImgState(!imgState)}>
      <img className="ven-img" src={image} alt="AHA Member Offer"></img>
      {imgState ? (
        ""
      ) : (
        <div className="img-open-wrapper fadeInAndUpQuick">
          <div className="img-open-frame">
            <div>
              <div className="img-close-btn">
                <Close20 />
              </div>
            </div>
            <div className="img-int-wrapper">
              <h3 className="grey img-title py-2">{title}</h3>
              <div className="img-wrapper">
                <img src={imageL} alt="Large content"></img>
              </div>
            </div>
          </div>
        </div>
      )}
    </button>
  );
};

export default ImgClick;
