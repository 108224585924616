import React from "react";
import { Link } from "react-router-dom";

import "./Sitemap.scss";

import { ArrowRight20 } from "@carbon/icons-react";

const Sitemap = () => {
  return (
    <div className="sim-grid">
      <Link to="/advisory" className="sim-item">
        <h2 className="mb-0">Advisory / Deals</h2>
        <h3 className="desc-toggle">
          We are proud to have advised and work with Australia's largest energy
          generator and distributors
        </h3>
        <ArrowRight20 />
      </Link>
      <Link to="/venture" className="sim-item">
        <h2 className="mb-0">Industry-Scale PPA</h2>
        <h3 className="desc-toggle">
          World First Industry-Scale PPA, a joint venture between the Australian
          Hotels Association and Clean Energy Strategies
        </h3>
        <ArrowRight20 />
      </Link>
      <Link to="/team" className="sim-item">
        <h2 className="mb-0">Team</h2>
        <h3 className="desc-toggle">
          Clean Energy Strategies team biographies
        </h3>
        <ArrowRight20 />
      </Link>
      <Link to="/contact" className="sim-item">
        <h2 className="mb-0">Contact</h2>
        <h3 className="desc-toggle">
          Clean Energy Strategies is a boutique corporate energy advisory firm
        </h3>
        <ArrowRight20 />
      </Link>
    </div>
  );
};

export default Sitemap;
