import React from "react";
import { Link } from "react-router-dom";
import "./Landing.scss";
import { ArrowRight20, ChevronRight16 } from "@carbon/icons-react";

const Landing = () => {
  return (
    <div className="lan-page">
      <div className="lan-grid">
        <div className="lan-item-1">
          <div className="lan-inn-item-1">
            <div className="div--mw  fadeInAndUp p-5">
              <h1 className="">Corporate Advisory + Energy Deals</h1>
              <p>
                Clean Energy Strategies is a boutique corporate energy advisory
                firm specialising in aggregate and industry-scale power purchase
                agreements.
              </p>
              <div className="nav-link  rm-btn">
                <Link to="/advisory" className="pl-0">
                  Learn More
                  <ChevronRight16 fill="black" id="btn-m" />
                </Link>
                <div className="underline-wrapper">
                  <div className="underline-bar"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="lan-inn-item-2 fadeIn">
            <img
              src="/assets/cleanenergy-logo.svg"
              alt="Clean Energy Strategies"
            ></img>
          </div>
        </div>
        <Link to="/venture" className="lan-item-2">
          <div className="lan-item-2-inner--wrapper">
            <div className="lan-item-3-inner-1 fadeIn">
              <h3>FEATURE</h3>
              <h4 className="my-0">World First Industry-Scale PPA</h4>
              <p>AHA / CES Joint Venture</p>
            </div>
          </div>
        </Link>
        <Link to="/why-are-energy-prices-so-high" className="lan-item-3">
          <div className="lan-item-2-inner--wrapper">
            <div className="lan-item-3-inner-1 fadeIn">
              <h3>ARTICLE</h3>
              <h4 className="my-0">Why are energy prices so high?</h4>
              <p>
                We are seeing an unprecedented rise in electricity prices
                across...
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Landing;
