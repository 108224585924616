import React from "react";
import { Link } from "react-router-dom";

import "./Footer.scss";
import Logo from "../Logo";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-sec1">
        <div className="logo-w">
          <Logo />
        </div>
        <div className="footer-list">
          <ul className="mt-5">
            <li>
              <Link to="/advisory">Advisory</Link>
            </li>
            <li className="pr-0">
              <Link to="/venture">PPAs</Link>
            </li>
            <li>
              <Link to="/team">Team</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </div>
      </div>
      <p className="mb-5">
        Clean Energy Strategies Level 61, MLC Centre, Martin Place, Sydney, NSW,
        2000, Australia +61 2 9221 1855 team@cleanenergystrategies.com.au
      </p>
    </div>
  );
};

export default Footer;
