import React from "react";

import "./ThankYou.scss";

const ThankYou = () => {
  return (
    <div className="ty-wrapper">
      <h1 className="pt-5">Thanks for your enquiry.</h1>
    </div>
  );
};

export default ThankYou;
